import React, { ReactNode } from 'react';

import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import AnimationWrapper from '@lupa/web/components/AnimationWrapper';
import { useRemusLoginRedirectToast } from '@lupa/web/hooks/use-remus-login-redirect-toast';

import { RouterLink } from '../components/RouterLink';
import { paths } from '../paths';

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backgroundImage: 'url("/assets/gradient-bg.svg")',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
}));

type AuthLayoutProps = {
  children: ReactNode;
};

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  useRemusLoginRedirectToast({ duration: 60 * 60 * 1000 });

  return (
    <LayoutRoot>
      <Box
        component='header'
        sx={{
          left: 0,
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Container maxWidth='lg'>
          <Stack
            direction='row'
            justifyContent='flex-end'
            spacing={2}
            sx={{ height: TOP_NAV_HEIGHT }}
          >
            <Stack
              alignItems='center'
              component={RouterLink}
              direction='row'
              display='inline-flex'
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  height: 28,
                  width: 28,
                }}
              >
                <ButtonBase
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  <img
                    src='/logo512.png'
                    alt='logo'
                    width='28px'
                    height='28px'
                    style={{ cursor: 'pointer', marginRight: 8 }}
                  />
                </ButtonBase>
              </Box>
              <Box
                sx={{
                  color: 'text.primary',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontSize: 14,
                  fontWeight: 800,
                  letterSpacing: '0.3px',
                  lineHeight: 2.5,
                  '& span': {
                    color: 'primary.main',
                  },
                }}
              >
                <span>Lupa |</span> AI Powered Petcare
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <AnimationWrapper displayInCenter>{children}</AnimationWrapper>
    </LayoutRoot>
  );
};
