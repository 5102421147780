import { SupabaseClient, createClient } from '@supabase/supabase-js';

import { IS_PROD } from '@lupa/web/utils/environment-utils';

import { TrpcRouterOutputs, trcpProxyClient } from './trpc';

let supabaseInternal: SupabaseClient | null = null;

// In prod we skip the API call to avoid an extra round trip before UI can load
const prodConfig: TrpcRouterOutputs['server']['getFrontendBootstrapConfig'] = {
  supabase: {
    url: 'https://auth.lupapets.com',
    anonKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhidWJjb3BzeWtwYmtubXFpbHl1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODM5MjkxODQsImV4cCI6MTk5OTUwNTE4NH0.TZ3KGKTVnFYzKf5yEIedcqLnEoe5q9SDAyI848bXz1o',
  },
};

const supabasePromise = (async () => {
  let config;

  if (IS_PROD) {
    config = prodConfig;
  } else {
    config = await trcpProxyClient.server.getFrontendBootstrapConfig.query();
  }

  supabaseInternal = createClient(config.supabase.url, config.supabase.anonKey);
  return supabaseInternal;
})();

export const getSupabase = async () => await supabasePromise;
export const getSupabaseAssumingAlreadyInitialized = () => {
  // Non async version for use in existing code - should only be used where we're confident supabase will already be initialized
  if (supabaseInternal == null) {
    throw new Error('Supabase client not yet initialized');
  }
  return supabaseInternal;
};
